//Import
import $ from 'jquery';
import select2 from 'select2';
//Hook up select2 to jQuery
select2($);

const initSelect2 = () => {
  $( '.select2' ).select2( {
    theme: "bootstrap-5",
    width: $( this ).data( 'width' ) ? $( this ).data( 'width' ) : $( this ).hasClass( 'w-100' ) ? '100%' : 'style',
    placeholder: $( this ).data( 'placeholder' ),
    allowClear: true
  } );
};

export { initSelect2 };
